import gql from 'graphql-tag';

import { getTeddlySdkApi } from '@config/teddly-sdk';
import { VendorDetails } from '@interfaces';


export async function getVendorsData(slug:string = null, is_fast_delivery:boolean = null): Promise<VendorDetails[]> {
  const { teddlyApolloClient } = await getTeddlySdkApi();
  return teddlyApolloClient
    .query({
      query: gql`
        query {
          vendors(first: 100, filter: {active: true ${slug ?  'slug: "' + slug + '"': ''}  ${is_fast_delivery !== null ?  'isFastDelivery: ' + is_fast_delivery : ''}} ) {
            edges {
              node {
                id
                name
                slug
                logoUrl
                address
                channel {
                  id
                  name
                  slug
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "network-only"
    })
    .then(({ data }) => {
      const items: VendorDetails[] = [];
      data.vendors.edges.forEach(({ node }) => {
        const { id, name, slug, logoUrl, address, channel} = node;
        items.push({
          id,
          name,
          slug,
          address,
          logo: logoUrl,
          channel
        });
      });
      return items;
    });
}
